.login-container {
  width: 100%;
  height: 100vh;
  background: url('~@/assets/img/login_bg.png');
  background-size: cover;
}
.login-container-form {
  position: fixed;
  top: 50%;
  left: 50%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: 600px;
  height: 758px;
  max-height: 80vh;
  min-height: 550px;
  padding: 6vh;
  background: #fff;
  background-size: 100% 100%;
  border-radius: 10px;
  box-shadow: 0 2px 8px 0 rgba(7, 17, 27, 0.06);
  transform: translate(-50%, -50%);
}
.login-container-logo {
  width: 14vh;
  height: 14vh;
}
.login-container-title {
  font-family: Source Han Sans CN, Source Han Sans CN;
  font-weight: 500;
  font-size: 22px;
  color: #000000;
  margin: 40px 0 50px;
  margin: 2.9vh 0 3.9vh;
  text-wrap: nowrap;
}
.login-container .ant-form {
  width: 400px;
}
.login-container .ant-form .ant-form-item {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  min-height: 64px;
}
.login-container .ant-form :deep .ant-form-item-label {
  margin-bottom: 13px;
}
.login-container .ant-form :deep .ant-form-item-label > label {
  height: 16px;
  font-family: Source Han Sans CN, Source Han Sans CN;
  font-weight: 400;
  font-size: 16px;
  color: #666666;
}
.login-container .ant-form :deep .ant-form-item-label > label::after {
  display: none;
}
.login-container .ant-form .ant-form-item-control-input {
  min-height: none;
}
.login-container .ant-form :deep .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none !important;
}
.login-container .ant-form .ant-input-affix-wrapper {
  width: 400px;
  height: 46px;
  border-radius: 4px;
}
.login-container .ant-form .ant-input-prefix {
  margin-right: 8px;
}
.login-container .ant-form .ant-input-prefix img {
  width: 16px;
  height: 16px;
}
.login-container .ant-form .anticon-eye-invisible,
.login-container .ant-form .anticon-eye {
  color: #999;
}
.login-container .ant-col {
  width: 100%;
  padding: 0 10px 0 10px;
}
.login-container .ant-input {
  height: 35px;
}
.login-container .ant-btn {
  width: 400px;
  height: 46px;
  border-radius: 4px;
  background: #4687FE;
  border-color: #4687FE;
  margin-top: 11px;
}
:deep .ant-form-horizontal .ant-form-item-control {
  flex: unset !important;
}
